@import '@core/styles/vars';

.SuggestionComments {
  $root: #{&};
  & {
    display: flex;
    flex-flow: nowrap column-reverse;
    padding: 0px;
    width: 100vw;
    max-width: 20em;
    max-height: 66vh;
    overflow: auto;
  }
  &-comment {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-bottom: 1em;
    transition: 0.25s;
    &_outgoing {
      flex-direction: row-reverse;
    }
    > * {
      padding-bottom: 0 !important;
    }
    &-text {
      position: relative;
      :global(.Badge) {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(25%, -50%);
        background: white;
      }
    }
    &-emoji {
      font-size: 2em;
      line-height: 1.33;
      margin: 0;
    }
    &-delete {
      border-radius: 100% !important;
      transition: 0.3s 0.5s;
      &:hover,
      &:focus {
        opacity: 1 !important;
        transition-delay: 0s !important;
      }
    }
    &:not(:hover) &-delete {
      opacity: 0;
      transition: 0.3s 0s;
    }
    &_deleting {
      opacity: 0.5;
      pointer-events: none;
    }
    :global(.DateLine) {
      display: inline;
    }
  }
  textarea {
    all: unset;
    margin: 0;
    width: 100%;
    border: none;
    box-sizing: border-box;
    border-top: 1px solid var(--color-border-default);
    outline: none;
    resize: none;
    padding: var(--sm);
    font-size: small;

    position: sticky;
    bottom: 0;
    background: #{rgba($gray50, 0.05)};
    min-height: 6em;
    transition: 0.25s;
  }
  &_saving {
    pointer-events: none;
    textarea {
      opacity: 0.5;
    }
  }
}
